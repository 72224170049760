<template>
  <div class="wrap">
    <TopHeader
      v-if="
        !['/nice', '/custom/builder'].some(
          (e) => $route.path.indexOf(e) !== -1
        ) &&
        !['/external/profile/factory'].some(
          (e) => $route.path.indexOf(e) !== -1
        )
      "
      @openLogin="openLogin"
      @matchingPopup="matchingPopup"
    />
    <div
      v-if="['/external/profile'].some((e) => $route.path.indexOf(e) !== -1)"
      class="rl-line"
    ></div>

    <div
      :class="{
        'wrap-web':
          (isMobile == 'false' || isMobile == false) &&
          ($route.matched[0]?.components.default.name.slice(-3).indexOf('Web') >
            -1 ||
            $route.path.indexOf('/mypage/') !== -1) &&
          !($route.path.indexOf('/mypage/profile') > -1) &&
          !($route.path.indexOf('/mypage/factory/auth') > -1) &&
          !($route.path.indexOf('/mypage/profile/complete') > -1) &&
          !($route.path.indexOf('/search/') > -1),
        'wrap-main':
          $route.path == '/' && (isMobile == 'false' || isMobile == false),
        login: loginUserId || $route.path == '/',
        'wrap-mobile':
          isMobile == 'true' ||
          isMobile == true ||
          $route.path.indexOf('/register') !== -1 ||
          $route.path.indexOf('/inquire') !== -1 ||
          $route.path.indexOf('payment/toss') !== -1 ||
          $route.path.indexOf('payment/external') !== -1 ||
          $route.path.indexOf('account') !== -1 ||
          $route.path.indexOf('/tel') !== -1,
        'wrap-etc': $route.path.indexOf('osleintro') !== -1,
      }"
    >
      <!-- <div
      :class="
        (isMobile == 'false' || isMobile == false) &&
        ($route.matched[0]?.components.default.name.slice(-3).indexOf('Web') >
          -1 ||
          $route.path.indexOf('/mypage/') !== -1 ||
          $route.path.indexOf('osleintro') > -1)
          ? 'wrap-web'
          : $route.path == '/' && (isMobile == 'false' || isMobile == false)
          ? loginUserId
            ? 'wrap-main login'
            : 'wrap-main'
          : 'wrap-mobile'
      "
    > -->
      <LeftDashboard
        v-if="
          (isMobile == 'false' || isMobile == false) &&
          ($route.matched[0]?.components.default.name.slice(-3).indexOf('Web') >
            -1 ||
            $route.path.indexOf('/mypage/') !== -1) &&
          !($route.path.indexOf('/mypage/profile') > -1) &&
          !($route.path.indexOf('/mypage/factory/auth') > -1) &&
          !($route.path.indexOf('/mypage/inquire') > -1) &&
          !($route.path.indexOf('/mall/') > -1) &&
          !($route.path.indexOf('/search/') > -1) &&
          !($route.path.indexOf('/designorder/') > -1) &&
          !($route.path.indexOf('/custom/') > -1)
        "
        @openLogin="openLogin"
        @matchingPopup="matchingPopup"
      />
      <div
        class="wrap-body"
        :class="{
          main:
            $route.matched[0]?.components.default.name.indexOf('MainPage') > -1,
          'max-wd100': $route.path.indexOf('osleintro') !== -1,
          full:
            $route.path.indexOf('/mall/') !== -1 ||
            $route.path.indexOf('/search/') !== -1 ||
            $route.path.indexOf('/designorder/') !== -1 ||
            $route.path.indexOf('/custom/') !== -1,
        }"
      >
        <div class="wrap-body-router flex1">
          <router-view
            @openLogin="openLogin"
            @openFindId="openFindId"
            @matchingPopup="matchingPopup"
            @customAddPopup="customAddPopup"
          ></router-view>
          <BottomFooter
            class="wrap-bottom-router"
            v-if="
              ![
                '/mypage',
                '/account',
                '/custom/builder',
                'designorder/detail/order',
                '/payment/external/',
              ].some((e) => $route.path.indexOf(e) !== -1)
            "
          ></BottomFooter>
        </div>
      </div>
    </div>

    <!-- v-if="
        (isMobile == 'false' || isMobile == false) &&
        ($route.matched[0]?.components.default.name.indexOf('MainPage') > -1 ||
          $route.path.indexOf('/terms/') > -1)
      " -->

    <BottomNavBar
      v-if="
        !['/account/login', '/nice'].some(
          (e) => $route.path.indexOf(e) !== -1
        ) &&
        $route.matched[0]?.components.default.name.indexOf('mainPage') == -1 &&
        windowWidth <= 800 &&
        !['/external/profile/factory'].some(
          (e) => $route.path.indexOf(e) !== -1
        )
      "
      @openLogin="openLogin"
      @matchingPopup="matchingPopup"
    />
    <div
      class="bubble pointer"
      :class="$store.state.meta.showMoreMenu ? '' : 'hidden'"
      @click="$store.state.meta.showMoreMenu = false"
    >
      <img :src="require(`@/assets/icon/ic-close.svg`)" />
    </div>
    <div :class="$store.state.meta.showMoreMenu ? '' : 'hidden'" class="menu">
      <div class="menu-item" @click="clickMenu('osle')">
        <img src="@/assets/icon/ic-osle.svg" />
        <p>오슬 살펴보기</p>
      </div>
      <div class="menu-item" @click="clickMenu('matching')">
        <img src="@/assets/black/li_shirt.svg" />
        <p>매칭하기란?</p>
      </div>
      <!-- <div class="menu-item" @click="clickMenu('mate')">
        <img src="@/assets/black/li_mate.svg">
        <p>오슬메이트란?</p>
      </div> -->
      <!-- <div class="menu-item" @click="clickMenu('faq')">
        <img src="@/assets/icon/ic-bookmark.svg" />
        <p>자주 묻는 질문</p>
      </div> -->
      <div class="menu-item" @click="clickMenu('inquire')">
        <img src="@/assets/icon/ic-edit.svg" />
        <p>문의하기</p>
      </div>
      <div
        id="channeltalk"
        class="menu-item bg-green"
        @click="clickMenu('channeltalk')"
      >
        <img src="@/assets/icon/ic-message-white.svg" />
        <p>실시간 채팅 상담</p>
      </div>
    </div>

    <div
      v-if="
        this.$store.state.meta.popupInfo.isShow &&
        this.$store.state.meta.popupInfo.id === 'showImg'
      "
      class="preview"
    >
      <div class="preview-background" @click="setPopup(false)"></div>
      <div class="preview-popup">
        <div class="preview-popup-top">
          <img
            class="close"
            @click="setPopup(false)"
            src="@/assets/icon/ic-x-space.svg"
          />
        </div>
        <div class="img-wrap">
          <img :src="this.$store.state.meta.popupInfo.url" />
        </div>
        <p class="btn-close" @click="setPopup(false)">닫기</p>
      </div>
    </div>

    <div class="modal" v-if="isModalShow">
      <div class="modal-background" @click="isModalShow = false"></div>
      <div class="modal-noti">
        <div class="modal-noti-top">
          <img :src="require(`@/assets/logo/osle-logo.svg`)" />
          <img
            class="close"
            @click="isModalShow = false"
            src="@/assets/icon/ic-x-space.svg"
          />
        </div>
        <div class="modal-noti-body">
          <!-- <img src="https://cdn.osle.co.kr:8443/banner/popup-sim.png" /> -->
          <div class="title">‘오슬상사’ <span>한정 SALE</span></div>
          <div class="contents">
            <p>시장보다 <span>50% 이상 저렴</span>한 금액 !</p>
            <p>딱 3개 제품 ‘각 1,000절’ <span>한정 수량</span></p>
            <p>망설이면 품절 ! 저렴할 때, 쟁여두세요 !</p>
          </div>
          <!-- <div class="step">
            <p>1. 20% ~ 50% 저렴한 금액</p>
            <p>2. 다양한 결제 수단</p>
            <p>3. 믿을 수 있는 퀄리티</p>
          </div> -->
          <div class="info">
            <!-- <div class="imgs">
              <img src="https://cdn.osle.co.kr:8443/main/main-sim1.png" />
              <img src="https://cdn.osle.co.kr:8443/main/main-sim2.png" />
              <img src="https://cdn.osle.co.kr:8443/main/main-sim3.png" />
              <img src="https://cdn.osle.co.kr:8443/main/main-sim4.png" />
            </div>
            <div>
              <p>상품은 주기적으로 추가될 예정입니다.</p>
              <p>
                계속해서 많은 관심 부탁드리며, <br />찾아주시는 모든 분들께
                진심으로 감사드립니다.
              </p>
            </div> -->
            <div class="info-content">
              <div
                class="info-content-list"
                @click="
                  gotoPage('/mall/materialdetail/34');
                  isModalShow = false;
                "
              >
                <div class="img">
                  <img src="@/assets/img/img-main-popup-sim1.png" />
                </div>
                <div class="item">
                  <p class="item-name">일반심지 트리코트 #흰색 (44인치)</p>
                  <p class="item-sale">할인가<span>66,000원</span></p>
                  <p class="item-price">33,800원<span>(1yd 당, 338원)</span></p>
                </div>
              </div>
              <div
                class="info-content-list"
                @click="
                  gotoPage('/mall/materialdetail/35');
                  isModalShow = false;
                "
              >
                <div class="img">
                  <img src="@/assets/img/img-main-popup-sim2.png" />
                </div>
                <div class="item">
                  <p class="item-name">일반심지 트리코트 #검정 (44인치)</p>
                  <p class="item-sale">할인가<span>66,000원</span></p>
                  <p class="item-price">42,300원<span>(1yd 당, 423원)</span></p>
                </div>
              </div>
              <div
                class="info-content-list"
                @click="
                  gotoPage('/mall/materialdetail/32');
                  isModalShow = false;
                "
              >
                <div class="img">
                  <img src="@/assets/img/img-main-popup-sim3.png" />
                </div>
                <div class="item">
                  <p class="item-name">실크심지 30데니아 (44인치)</p>
                  <p class="item-sale">할인가<span>55,000원</span></p>
                  <p class="item-price">30,000원<span>(1yd 당, 600원)</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-noti-btns">
          <div class="modal-noti-btns-btn">
            <p class="modal-noti-btns-btn-left" @click="isModalShow = false">
              닫기
            </p>
            <p
              class="modal-noti-btns-btn-right"
              @click="
                isModalShow = false;
                gotoPage('/mall/materiallist');
              "
            >
              이동하기
            </p>
          </div>
          <div class="modal-noti-btns-chk" @click="closeWeek()">
            <input type="checkbox" id="agree" class="pointer" />
            <label class="pointer" for="agree">
              <p>7일간, 보지 않기</p>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-show="isLogin">
      <div class="modal-background" @click="isLogin = false"></div>
      <div class="modal-login">
        <img
          class="close"
          @click="isLogin = false"
          src="@/assets/icon/ic-x.svg"
        />
        <div class="modal-login-top">
          <img src="@/assets/logo/osle-logo.svg" />
        </div>
        <div class="modal-login-body">
          <div class="input-wrap">
            <p class="name">이메일 (아이디)</p>
            <div
              class="input"
              :class="[{ active: userId }, { error: userIdCheck }]"
            >
              <input
                type="email"
                @input="checkId()"
                @change="checkId()"
                @keyup.enter="goLogin()"
                v-model="userId"
                class="txt"
                placeholder="아이디를 입력해주세요."
              />
            </div>
            <p v-if="isIdError" class="error-msg">
              오슬에 가입되어 있지 않은 아이디 입니다.
            </p>
          </div>
          <div class="input-wrap">
            <p class="name">비밀번호</p>
            <div class="input" :class="{ active: userPwCheck }">
              <input
                v-model="userPw"
                @input="checkPw()"
                @change="checkPw()"
                @keyup.enter="goLogin()"
                class="txt"
                placeholder="비밀번호를 입력해주세요."
                :type="isShowPassword ? '' : 'password'"
                maxlength="18"
                autocomplete="new-password"
              />
              <img
                class="eye pointer"
                :src="
                  require(`@/assets/icon/ic-eye-${
                    isShowPassword ? 'on' : 'off'
                  }.svg`)
                "
                @click="showPassword()"
              />
            </div>
            <div class="pw-explain" :class="pwRequireAll ? 'active' : 'error'">
              <span :class="pwRequire[0] ? 'active' : ''">영문</span>
              &nbsp;|&nbsp;
              <span :class="pwRequire[1] ? 'active' : ''">숫자</span>
              &nbsp;|&nbsp;
              <span :class="pwRequire[2] ? 'active' : ''">특수문자</span>
              &nbsp;|&nbsp;
              <span :class="pwRequire[3] ? 'active' : ''">8~18자리</span>
            </div>
            <p v-if="isPwError" class="error-msg pw">
              비밀번호가 일치하지 않습니다.
            </p>
          </div>
          <div class="func">
            <div class="auto-login" @click="autoLogin = !autoLogin">
              <img
                :src="
                  require(`@/assets/icon/ic${
                    autoLogin ? '-check' : ''
                  }-square.svg`)
                "
              />
              <p class="drag-block pointer">로그인 상태 유지</p>
            </div>
            <div class="find">
              <p
                @click="
                  isLogin = false;
                  gotoPage('/account/find-id');
                "
                class="drag-block pointer"
              >
                아이디·비밀번호 찾기
              </p>
            </div>
          </div>

          <div class="btns">
            <p class="before-login" v-if="tempSocialType">
              이전에 <span v-if="tempSocialType == 'normal'">이메일</span
              ><span v-else-if="tempSocialType == 'kakao'">카카오</span
              ><span v-else-if="tempSocialType == 'naver'">네이버</span>로
              로그인 했어요 !
            </p>
            <div
              class="btn drag-block top"
              :class="userId && userPwCheck ? 'active' : ''"
              @click="goLogin()"
            >
              <p>로그인</p>
            </div>
          </div>

          <div class="sns">
            <p class="sns-title">SNS 간편 로그인</p>
            <div class="sns-wrap">
              <div class="sns-wrap-kakao pointer" @click="clickKakaoLogin()">
                <div class="sns-btn">
                  <img src="@/assets/logo/kakao-logo.svg" />
                  <p>카카오톡</p>
                </div>
                <p v-if="isKakaoId" class="error-msg">가입 내역이 없습니다.</p>
              </div>
              <div class="sns-wrap-naver pointer" @click="clickNaverLogin()">
                <div class="sns-btn">
                  <img src="@/assets/logo/naver-logo.svg" />
                  <div>네이버</div>
                </div>
                <p v-if="isNaverId" class="error-msg">가입 내역이 없습니다.</p>
              </div>
            </div>
          </div>

          <div
            class="signup"
            @click="
              isLogin = false;
              gotoPage('/account/register/type');
            "
          >
            <p>회원 가입</p>
          </div>
        </div>
      </div>
    </div>

    <div class="modal top" v-if="$store.state.meta.msgPopupInfo.msg">
      <div
        class="modal-background"
        @click="$store.state.meta.msgPopupInfo.msg = ''"
      ></div>
      <div class="modal-wrap">
        <p v-html="$store.state.meta.msgPopupInfo.msg"></p>
        <div class="btns">
          <p class="btns-btn" @click="clickMsgPopupBtn()">확인</p>
        </div>
      </div>
    </div>

    <div class="hidden" id="naver_id_login"></div>
    <textarea class="hidden" id="copyText"></textarea>
  </div>

  <div class="modal" v-if="isInquire">
    <div class="modal-background"></div>
    <div class="modal-find-id">
      <div class="modal-find-id-wrap">
        <p class="inquire-title">아이디 통합 요청 문의가 접수되었습니다.</p>
        <p class="inquire-content">
          해당 담당자가<br />
          최대한 빠르게 연락드리겠습니다.
        </p>
      </div>
      <div @click="isInquire = false" class="find-id-bottom active">
        <div>
          <p>확인</p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-if="$store.state.meta.loginInfo.isShowFindId">
    <div class="modal-background"></div>
    <div class="modal-find-id">
      <div
        class="modal-find-id-wrap"
        v-if="$store.state.meta.loginInfo.userIds.length > 0"
      >
        <div class="modal-find-id-wrap-top">
          <p>동일한 휴대전화 번호로</p>
          <p>가입된 아이디가 존재합니다.</p>
        </div>
        <div class="modal-find-id-wrap-body">
          <div class="find-normal">
            <p class="title">오슬 가입 아이디</p>
            <div class="mt-12">
              <div class="found-id">
                <p>-</p>
                <p>{{ $store.state.meta.loginInfo.normalUserId.userId }}</p>
              </div>
              <div class="last-access-date">
                <p></p>
                <p>
                  최근 접속일 :
                  {{ $store.state.meta.loginInfo.normalUserId.lastAccessDate }}
                </p>
              </div>
            </div>
            <p class="find-pw-btn" @click="goFindPw()">비밀번호 찾기</p>
          </div>
          <div class="find-sns">
            <p class="title">SNS 간편 가입 아이디</p>
            <div class="content mt-12">
              <div class="kakao">
                <div class="found-id">
                  <p>-</p>
                  <p>카카오톡</p>
                </div>
                <div class="last-access-date">
                  <p></p>
                  <p>
                    최근 접속일 :
                    {{ $store.state.meta.loginInfo.kakaoUserId.lastAccessDate }}
                  </p>
                </div>
              </div>
              <div class="naver mt-20">
                <div class="found-id">
                  <p>-</p>
                  <p>네이버</p>
                </div>
                <div class="last-access-date">
                  <p></p>
                  <p>
                    최근 접속일 :
                    {{ $store.state.meta.loginInfo.naverUserId.lastAccessDate }}
                  </p>
                </div>
              </div>
            </div>
            <p class="desc">
              <span>※</span>간편 가입의 경우, 해당 서비스에서 비밀번호를
              변경하시거나 찾으셔야 합니다.
            </p>
          </div>
          <div>
            <p class="inquire-btn" @click="reqMergeUser()">
              통합 요청/문의 하기
            </p>
          </div>
        </div>
      </div>
      <div class="modal-find-id-wrap" v-else>
        <p class="empty">가입 내역이 없습니다.</p>
      </div>
      <div
        @click="$store.state.meta.loginInfo.isShowFindId = false"
        class="find-id-bottom active"
      >
        <div>
          <p>확인</p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showModal" class="modal">
    <div class="modal-background" @click="showModal = false"></div>
    <div class="modal-wrap">
      <p>
        휴면 계정입니다. 휴면 해제 후 오슬 플랫폼 서비스를 이용할 수
        있습니다.<br />휴면 해제 하시겠습니까?
      </p>
      <div class="btns">
        <p class="btns-btn" @click="showModal = false">취소</p>
        <p
          class="btns-btn"
          @click="
            showModal = false;
            dormantToggle();
          "
        >
          해제
        </p>
      </div>
    </div>
  </div>

  <div v-if="isMatchingFin" class="modal">
    <div class="modal-background" @click="isMatchingFin = false"></div>
    <div class="modal-matching-fin">
      <img
        class="close"
        @click="matchingFin(false)"
        src="@/assets/icon/ic-x.svg"
      />
      <div class="modal-matching-fin-body">
        <p>매칭 등록이 완료되었습니다.</p>
      </div>

      <div class="modal-matching-fin-bottom">
        <p class="btn-right" @click="matchingFin(false)">확인</p>
        <p
          class="btn-left"
          @click="
            isMatchingFin = false;
            isMatchingPopup = true;
          "
        >
          추가 등록
        </p>
      </div>
    </div>
  </div>

  <div
    class="toast-msg"
    :class="{
      'showing-toast': $store.state.meta.toastMsg,
    }"
  >
    <p>{{ $store.state.meta.toastMsg }}</p>
  </div>
  <DesignOrderAddPopup
    v-if="isMatchingPopup && this.$store.state.meta.windowWidth >= 900"
    @matchingPopup="matchingPopup"
    @matchingFin="matchingFin"
  />
  <DesignOrderAddPopupMo
    v-if="isMatchingPopup && this.$store.state.meta.windowWidth < 900"
    @matchingPopup="matchingPopup"
    @matchingFin="matchingFin"
  />
  <CustomMobileInfo v-if="isCustomMobile" />
</template>
<script>
import TopHeader from "./pages/comm/base/TopHeader.vue";
import LeftDashboard from "./pages/comm/base/LeftDashboard.vue";
import BottomFooter from "./pages/comm/base/BottomFooter.vue";
import BottomNavBar from "./pages/comm/base/BottomNavBar.vue";
import sha256 from "crypto-js/sha256";

import "swiper/css";
import "swiper/css/pagination";

export default {
  name: "App",
  components: {
    TopHeader,
    LeftDashboard,
    BottomFooter,
    BottomNavBar,
  },
  data() {
    return {
      isCustomMobile: false,
      isMatchingFin: false,
      isMatchingPopup: false,
      isModalShow: false,
      modalId: "mallModal",
      isModalFirst: true,
      footerBlackList: [
        "/account",
        "/nice",
        "/designorder/add",
        "/estimate",
        "/mateestimate",
        "/matchingconfirm",
        "/mateconfirm",
        "/mypage/menu",
        "/mypage/profile",
        "/matching/",
        "/mate/",
        "/matchingdetails",
      ],
      isMobile: "",
      userId: "",
      loginUserId: sessionStorage.getItem("userId"),
      userPw: "",
      isLogin: false,
      isKakaoId: false,
      isNaverId: false,
      isIdError: false,
      isPwError: false,
      userIdCheck: false,
      userPwCheck: false,
      isShowPassword: false,
      autoLogin: true,
      pwRequire: [false, false, false, false],
      pwRequireAll: true,
      notBack: false,
      showModal: false,
      naverDormantId: "",
      naverDormantToken: "",
      isMismatch: false,
      isInquire: false,
      tempSocialType: "",
      login: {
        naverClientId: "",
        naverClientSecret: "",
        loginUrl: "",
        userId: "",
        accessToken: "",
        accessTokenSha: "",
        loginType: "",
        compType: "",
        marketingFlag: "",
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.meta.windowWidth;
    },
  },
  // watch: {
  //   '$route' (to) {
  //     if (this.isModalFirst && !this.getCookie('simModal') && (to.path === '/' || to.path === '/dashboard')) {
  //       this.isModalShow = true
  //       this.isModalFirst = false
  //     } else {
  //       this.isModalFirst = false
  //     }
  //   }
  // },
  beforeMount() {
    window.addEventListener("resize", this.pageResize);
    if (!localStorage.getItem("uuid")) {
      // uuid가 없다면
      localStorage.setItem("uuid", this.createUUID());
    }
    if (
      !sessionStorage.getItem("userId") &&
      localStorage.getItem("autoLogin")
    ) {
      // 자동로그인
      let userId = sessionStorage.getItem("userId")
        ? sessionStorage.getItem("userId")
        : localStorage.getItem("userId");
      let userPw = localStorage.getItem("userPw")
        ? localStorage.getItem("userPw")
        : sessionStorage.getItem("userPw");
      if (localStorage.getItem("socialType") == "normal")
        userPw = sha256(userPw);
      this.$API.account
        .login(userId, userPw)
        .then((res) => {
          if (res.data.resultCode === "10000") {
            this.loginInfoSet(true, res.data.body);
            this.$router.go(0);
          } else {
            this.loginInfoSet(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        const userId = sessionStorage.getItem("userId")
          ? sessionStorage.getItem("userId")
          : localStorage.getItem("userId");
        const compType = sessionStorage.getItem("compType")
          ? sessionStorage.getItem("compType")
          : localStorage.getItem("compType");
        /* router path가 변경될 때마다 서버로 접근로그를 저장한다. */
        // axios.post("/lc/access-log");
        //공통 데이터 호출 후 전역에 저장
        if (userId) {
          this.$API.comm
            .commData(userId, compType)
            .then((res) => {
              this.$store.state.meta.commData = res.data.body;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      if (!this.getCookie("mallModal") && to.path == "/") {
        // this.isModalShow = true;
      }

      // if (this.$route.path.indexOf("/custom/") >= 0 && this.isMobile) {
      //   this.gotoPage("/mobile/info/custom");
      // }
      console.log(this.isCustomMobile);
    },
  },
  mounted() {
    //간편 로그인 init..
    //네이버
    this.loginloginUrl = location.href.replace(
      location.pathname == "/" ? "" : location.pathname,
      ""
    );
    if (document.location.href.indexOf("osle.co.kr") > -1) {
      this.loginnaverClientId = "FAl81Aa1vbW3sqvYuvo5";
      this.loginnaverClientSecret = "6uqalS1fWD";
    } else {
      this.loginnaverClientId = "DVrtBurdxQ5ZricDOvom";
      this.loginnaverClientSecret = "eF9gl169Lp";
    }
    //카카오
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init("ea8c76a7c78e6c0e8fe22c364b9134c7");
    }

    if (sessionStorage.getItem("userId")) {
      this.userId = sessionStorage.getItem("userId");
    }
    if (this.getCookie("socialType")) {
      this.tempSocialType = this.getCookie("socialType");
    }
    this.sendVisit();
    this.pageResize();
    this.loadScript();
    // this.isMobile =
    //   navigator.userAgent.indexOf("iPhone") > -1 ||
    //   navigator.userAgent.indexOf("Android") > -1;

    if (
      navigator.userAgent.match(
        /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
      ) != null ||
      navigator.userAgent.match(/LG|SAMSUNG|Samsung/) != null ||
      (navigator.userAgent.match(/Windows NT/) != null &&
        window.matchMedia("only screen and (max-width: 768px)").matches)
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    sessionStorage.setItem("isMobile", this.isMobile);
    localStorage.setItem("isMobile", this.isMobile);

    // alert(navigator.userAgent);
    // window.addEventListener('tokenSend', this.tokenTest, false)
    window.addEventListener("flutterInAppWebViewPlatformReady", () => {
      // this.showToast(navigator.userAgent);
      window.flutter_inappwebview
        .callHandler("tokenSend", "*** Message From Server JS ***")
        .then(function (result) {
          this.showToast("토큰 이벤트 받음");
          this.showToast(result);
        });
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.pageResize);
    // window.removeEventListener('tokenSend', this.tokenTest)
  },
  methods: {
    matchingFin(isActive) {
      console.log(isActive);
      if (isActive) {
        this.isMatchingPopup = false;
        this.isMatchingFin = isActive;
        // this.isMatchingPopup = true;
      } else {
        location.reload();
      }
    },
    matchingPopup(isActive) {
      console.log(isActive);
      this.isMatchingPopup = isActive;
    },
    customAddPopup(isActive) {
      console.log(isActive);
      if (this.userId) {
        // this.isCustomAdd = isActive;

        this.$API.custom.customRegist(this.userId).then((res) => {
          let getUrl = "/custom/builder/" + res.data.body + "?&step=0";
          this.gotoPage(getUrl);
          this.sendBtnEvent("8", "SU");
        });
        // this.gotoPage("/custom/builder");
      } else {
        this.goLogin();
      }
    },
    goFindPw() {
      this.$store.state.meta.loginInfo.isShowFindId = false;
      this.$store.state.meta.loginInfo.isFindId = false;
      this.gotoPage("/account/find-id");
    },
    openFindId(type) {
      this.$store.state.meta.loginInfo.isShowFindId = type;
    },
    reqMergeUser() {
      this.$store.state.meta.loginInfo.isShowFindId = false;
      this.$API.account
        .reqMergeUser(localStorage.getItem("telNum"))
        .then((res) => {
          this.isInquire = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openLogin() {
      this.isLogin = true;
    },
    showPassword() {
      this.isShowPassword = !this.isShowPassword;
    },
    checkId() {
      // const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      // this.userIdCheck = regex.test(this.userId)
      if (this.userId) {
        this.userIdCheck = false;
      } else {
        this.userIdCheck = true;
      }
    },
    checkPw() {
      const regex =
        /^.*(?=^.{8,18}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
      this.userPwCheck = regex.test(this.userPw);

      const regex1 = /[a-zA-Z]/;
      const regex2 = /[0-9]/;
      const regex3 = /[!@#$%^&+=]/;
      this.pwRequire[0] = regex1.test(this.userPw);
      this.pwRequire[1] = regex2.test(this.userPw);
      this.pwRequire[2] = regex3.test(this.userPw);
      this.pwRequire[3] = this.userPw.length >= 8 && this.userPw.length <= 18;

      this.pwRequireAll =
        this.pwRequire[0] &&
        this.pwRequire[1] &&
        this.pwRequire[2] &&
        this.pwRequire[3];
    },

    goLogin() {
      localStorage.setItem("socialType", "normal");
      localStorage.setItem("userPw", this.userPw);
      this.checkId();
      this.callLogin(this.userId, this.userPw, true, this.afterLogin);
    },

    clickSignup() {
      localStorage.setItem("socialType", "normal");
      this.$store.state.meta.loginInfo.socialType = "normal";
      this.gotoPage("/account/register");
    },
    clickNaverLogin() {
      const naver_id_login = new window.naver_id_login(
        this.loginnaverClientId,
        this.loginloginUrl + "/login/naver"
      );
      const state = naver_id_login.getUniqState();
      naver_id_login.setDomain(this.loginloginUrl + "/login/naver");
      naver_id_login.setButton("green", 3, 40);
      naver_id_login.setState(state);
      naver_id_login.setPopup();
      naver_id_login.init_naver_id_login();
      localStorage.setItem("socialType", "naver");
      this.$store.state.meta.loginInfo.socialType = "naver";
      let btnNaverLogin = document.getElementById("naver_id_login").firstChild;
      btnNaverLogin.click();
      setTimeout(() => {
        this.isNaverId = true;
      }, 800);
    },
    clickKakaoLogin() {
      localStorage.setItem("socialType", "kakao");
      this.$store.state.meta.loginInfo.socialType = "kakao";
      window.Kakao.Auth.login({
        success: (response) => {
          this.loginType = "kakao";
          this.accessToken = response.access_token;
          this.accessTokenSha = sha256(response.refresh_token_expires_in);

          this.compType = this.$store.state.meta.registerCompType;
          this.marketingFlag = this.$store.state.meta.registerMarketingFlag;
          this.$store.state.meta.registerCompType = "";
          this.$store.state.meta.registerMarketingFlag = "";

          this.$API.account
            .loginCheckToken(this.accessToken, "kakao")
            .then((res) => {
              var easyUrl = "";
              let userId = res.data.body.userId;
              let di = res.data.body.di;
              let compType = res.data.body.compType;
              localStorage.setItem("userId", userId);
              localStorage.setItem("accessToken", this.accessToken);
              localStorage.setItem("useraccessTokenShad", this.accessTokenSha);
              localStorage.setItem("userPw", this.accessTokenSha);
              localStorage.setItem("loginType", "login");
              easyUrl = "/user/login/easy";
              // 회원가입 & 로그인
              this.$API.account
                .loginEasy(
                  easyUrl,
                  this.accessToken,
                  this.accessTokenSha,
                  this.compType,
                  this.marketingFlag,
                  this.loginType
                )
                .then((res) => {
                  const data = res.data;
                  this.userId = data.body.userId;
                  if (data.resultCode == "10000") {
                    if (compType) {
                      this.callLogin(
                        this.userId,
                        this.accessTokenSha,
                        false,
                        this.afterLogin
                      );
                    } else {
                      localStorage.setItem("userId", this.userId);
                      this.gotoPage("/account/register");
                    }
                  } else if (data.resultCode == "60031") {
                    this.userId = data.body.userId;
                    // this.showModal = true;
                    this.isKakaoId = true;
                    this.dormantToggle(this.accessTokenSha, false);
                  } else {
                    localStorage.setItem("userId", this.userId);
                    this.isKakaoId = true;
                  }
                });
            });
        },
        fail: (error) => {
          console.log(error);
        },
      });
    },
    afterLogin(isSuccess, resultMsg, resultCode) {
      if (isSuccess) {
        localStorage.setItem("autoLogin", true);
        localStorage.setItem("userId", this.userId);
        // localStorage.setItem(
        //   "userPw",
        //   this.accessTokenSha ? this.accessTokenSha : this.userPw
        // );
        localStorage.setItem("userPwEncrypt", "******");
        this.notBack = true;
        this.$router.go(0);
      } else {
        if (resultCode == "60031") {
          // this.showModal = true;
          this.dormantToggle(this.userPw, true);
        } else {
          this.showToast(resultMsg);
          // this.setMsgPopup(true, resultMsg);
          if (resultCode == "80001") {
            this.isIdError = true;
          } else if (resultCode == "80002") {
            this.isPwError = true;
          }
        }
      }
    },
    dormantToggle(userPw, isPwEncoding) {
      this.$API.account.dormantToggle(this.userId).then(() => {
        this.callLogin(this.userId, userPw, isPwEncoding, this.afterLogin);
      });
    },
    rand4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
    createUUID() {
      return `${this.rand4()}${this.rand4()}-${this.rand4()}-${this.rand4()}-${this.rand4()}-${this.rand4()}${this.rand4()}${this.rand4()}`;
    },
    clickMenu(menuName) {
      if (menuName === "osle") {
        this.gotoPage("/osleintro");
      } else if (menuName === "matching") {
        this.gotoPage("/matchingintro");
      } else if (menuName === "mate") {
        this.gotoPage("/mateintro");
      } else if (menuName === "inquire") {
        this.gotoPage("/mypage/inquire");
      } else if (menuName === "faq") {
        this.gotoPage("/mypage/faq");
      }
      this.$store.state.meta.showMoreMenu = false;
    },
    closeWeek() {
      this.isModalShow = false;
      this.setCookie("mallModal", "mallClose", 7);
    },
    clickMsgPopupBtn() {
      this.$store.state.meta.msgPopupInfo.msg = "";
      if (this.$store.state.meta.msgPopupInfo.func) {
        this.$store.state.meta.msgPopupInfo.func();
      }
    },
    pageResize() {
      this.$store.state.meta.windowWidth = window.innerWidth;
    },
    tokenTest() {
      this.showToast("토큰 이벤트 받음");
    },
    toggleData2(key) {
      console.log(key);
      // console.log(value)
      // this[key] = value
    },
  },
};
</script>

<style lang="scss" scoped>
.account-main-wrap {
  .account-main {
    position: relative;
    max-width: 520px;
    margin: 0 auto;
    @include set-height();
    @media (min-width: 501px) {
      border: 1px solid $neutral-10;
      border-width: 0px 1px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
    }
  }
}
.wrap {
  margin: 0 auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  // flex-direction: column;
  // align-items: center;
  justify-content: center;
  position: relative;
}
.wrap-mobile {
  // @include set-width-pc();
  max-width: 520px;
  width: 100%;
}
.wrap-web {
  // @include set-width-pc();
  width: 1290px;
  display: flex;
  gap: 60px;
  position: relative;
  height: calc(100vh - 70px);
  // padding-bottom: 100px;
}
.wrap-main {
  // @include set-width-pc();
  width: 1290px;
  display: flex;
  justify-content: center;
  gap: 60px;
  position: relative;
  // margin-top: 340px;
  &.login {
    width: 1290px;
  }
  // padding-bottom: 100px;
}
.wrap-etc {
  width: 100%;
}
.wrap-body {
  &.main {
    max-width: 1240px;
  }
  // margin-bottom: 100px;
  // max-width: 500px;
  @media (max-width: 800px) {
    max-width: 520px;
  }
  @media (min-width: 1291px) {
    max-width: rem(960px);
  }
  width: 100%;
  @include set-height();
  display: flex;
  flex-direction: column;
  // @include tablet {
  //   background-color: $neutral-0;
  // }
  // @include desktop {
  //   background-color: $neutral-0;
  // }
  &.full {
    max-width: 1290px;
  }
}

.bubble {
  z-index: 700;
  position: fixed;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.12));
  background-color: $primary-50;
  right: rem(16px);
  bottom: rem(76px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(48px);
  height: rem(48px);
  border-radius: 24px;
  @include tablet {
    display: none;
  }
}
.menu {
  z-index: 700;
  position: fixed;
  right: rem(16px);
  bottom: rem(137px);
  box-shadow: 4px 4px 6px rgba(133, 128, 128, 0.25);
  border-radius: 8px;
  background-color: $neutral-5;
  padding: rem(8px);
  @include tablet {
    right: calc(50% - 550px + 220px);
    top: 76px;
    // height: 297px;
    height: 247px;
  }
  @include desktop {
    right: calc(50% - 550px + 120px);
  }
  &-item {
    background: $neutral-0;
    border-radius: 8px;
    min-width: rem(174px);
    padding: rem(10px) rem(16px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    color: $neutral-80;
    cursor: pointer;
    &:not(:first-child) {
      margin-top: rem(8px);
    }
    img {
      width: rem(16px);
      height: rem(16px);
      margin-right: rem(8px);
    }
    &.bg-green {
      background-color: $indication-50;
      color: $neutral-0;
    }
  }
}
.preview {
  &-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 100;
  }
  background-color: $neutral-0;
  &-popup {
    padding-bottom: 0;
    max-width: 1100px !important;
    margin: 0 auto !important;
    position: fixed;
    z-index: 551;
    width: 100%;
    height: calc(100% - 150px);
    overflow-y: auto;
    background-color: #ffffff;
    max-height: 100vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    &-top {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  .img-wrap {
    width: 100%;
    height: 100%;
    background-color: $neutral-0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      height: calc(100vh - 200px);
      object-fit: contain;
    }
  }
  .btn-close {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    width: 80%;
    max-width: 320px;
    background-color: $neutral-10;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    opacity: 0.5;
    padding: 12px;
    border-radius: 7px;
    cursor: pointer;
  }
}

.red {
  color: $secondary-60;
  font-size: 16px;
  font-weight: bold;
  @include tablet {
    font-size: 18px;
  }
}
.modal {
  .scroll {
    max-height: 730px;
  }
  &-login {
    overflow: scroll;
    position: fixed;
    z-index: 1001;
    top: 50%;
    left: 50%;
    max-width: 360px;
    max-height: 730px;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: $neutral-0;
    border-radius: 8px;
    padding: 40px 20px;
    text-align: center;
    @media (max-height: 780px) {
      max-height: calc(100vh - 100px);
    }
    .close {
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
    }
    &-top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-body {
      margin-top: 40px;
      .input-wrap {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        margin-bottom: 28px;
        position: relative;
        .name {
          text-align: left;
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
        }
        .input {
          margin-top: 12px;
          display: flex;
          align-items: center;

          padding: 4px 8px;
          background: $neutral-0;
          border-width: 0px 0px 1px 1px;
          border-style: solid;
          border-color: $neutral-20;
          border-radius: 4px;
          width: 100%;
          height: 32px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          &.error {
            border-color: $secondary-60;
          }
          &:focus-within {
            border-color: $primary-50;
          }
          &:hover {
            background: #fffbf2;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
            input {
              background: #fffbf2;
            }
          }
          .txt {
            flex: 1;
          }
          .eye {
            width: 16px;
            height: 16px;
          }
          .time {
            margin-right: rem(9px);
            color: $primary-60;
          }
          input {
            width: 100%;
            height: 100%;
            background: $neutral-0;
            font-size: 15px;
            font-weight: 400;
            line-height: 23px;
            &:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px white inset !important;
            }
            &:hover {
              background: #fffbf2;
            }
          }
        }
        .error-msg {
          position: absolute;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: $secondary-60;
          bottom: -22px;
          right: 0;
          &.pw {
            bottom: -40px;
          }
        }
        .pw-explain {
          position: absolute;
          bottom: -20px;
          right: 0;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: right;
          margin-top: 2px;
          color: $neutral-30;
          .active {
            color: $primary-50;
          }
        }
      }
      .pw-check {
        margin-top: rem(8px);
      }
      .func {
        margin-top: 48px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $neutral-60;
        .find {
          display: flex;
          gap: 20px;
          border-bottom: 1px solid $neutral-0;
          p {
            align-items: center;
          }
          &:hover {
            border-bottom: 1px solid $neutral-10;
          }
        }
        .auto-login {
          display: flex;
          align-items: center;
          gap: 8px;
          border-bottom: 1px solid $neutral-0;
          label {
            margin-left: rem(5px);
          }
          &:hover {
            border-bottom: 1px solid $neutral-10;
          }
        }
      }
      .btns {
        padding: 40px 0 20px;
        border-bottom: 1px solid $primary-20;
        .before-login {
          margin-bottom: 12px;
          color: $neutral-60;
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
          span {
            color: $primary-60;
          }
        }
        .btn {
          height: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s;
          background: $neutral-30;
          border-radius: 4px;
          color: $neutral-0;
          background-color: $neutral-30;
          font-weight: 500;
          font-size: 15px;
          line-height: 23px;
          &.active {
            background-color: $primary-50;
            &:hover {
              background-color: $primary-60;
            }
          }
        }
      }
      .sns {
        display: flex;
        flex-direction: column;
        padding: 20px 0 40px;
        gap: 12px;
        border-bottom: 1px solid $primary-20;
        &-title {
          width: 100%;
          text-align: left;
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
        }
        &-wrap {
          display: flex;
          gap: 20px;
          width: 100%;
          .sns-btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            gap: 8px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            height: 40px;
            img {
              width: 24px;
              height: 24px;
            }
          }
          &-kakao {
            color: #311b1c;
            background: #f9e000;
            border-radius: 4px;
            width: 100%;
          }
          &-naver {
            color: $neutral-0;
            background: #00c43b;
            border-radius: 4px;
            width: 100%;
          }
          .error-msg {
            position: absolute;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $secondary-60;
            margin-left: 20px;
          }
        }
      }
      .signup {
        margin-top: 20px;
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 500;
        line-height: 23px;
        border: 1px solid $neutral-20;
        color: $neutral-60;
        &:hover {
          border-color: $primary-20;
        }
      }
    }
  }

  &-find-id {
    position: fixed;
    z-index: 1001;
    max-width: 360px;
    width: 100%;
    min-height: 208px;
    left: 50%;
    top: 100px;
    transform: translate(-50%, 0);
    background-color: $neutral-0;
    border-radius: 8px;
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.04));
    &-wrap {
      position: relative;
      padding: 40px 20px;
      &-top {
        display: flex;
        flex-direction: column;
        gap: 4px;
        color: $neutral-60;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-bottom: 40px;
      }
      &-body {
        .find-normal {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid $primary-20;
        }
        .found-id {
          display: flex;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          :last-child {
            position: absolute;
            left: 40px;
          }
        }
        .last-access-date {
          margin-top: 4px;
          display: flex;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          :last-child {
            position: absolute;
            left: 40px;
          }
        }
        .find-pw-btn {
          cursor: pointer;
          margin-top: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $neutral-60;
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
          height: 40px;
          width: 100%;
          border-radius: 4px;
          border: 1px solid $neutral-20;
        }
        .desc {
          margin-top: 40px;
          color: $neutral-60;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
        .inquire-btn {
          cursor: pointer;
          margin: 40px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          height: 40px;
          width: 100%;
          border-radius: 4px;
          border: 1px solid $neutral-20;
        }
      }
      .title {
        display: flex;
        // align-items: center;
        // justify-content: center;
        color: $primary-60;
        font-size: 15px;
        font-weight: 500;
        line-height: 23px;
      }
      .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .empty {
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .inquire-title {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
      }
      .inquire-content {
        margin: 28px 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
      }
    }
    .find-id-bottom {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 23px;
      height: 40px;
      color: $neutral-0;
      position: absolute;
      bottom: 0;
      width: 100%;
      border-radius: 0px 0px 8px 8px;
      background-color: $neutral-30;
      &.active {
        background-color: $primary-50;
      }
    }
  }

  &-noti {
    position: fixed;
    z-index: 1001;
    left: 50%;
    max-width: 360px;
    width: 100%;
    // max-height: 567px;
    // height: 100%;
    transform: translate(-50%, 0);
    background-color: $neutral-0;
    border-radius: 16px;
    text-align: center;
    color: $neutral-80;
    // @media (max-width: 430px) {
    //   max-width: 360px;
    //   width: 100%;
    //   max-height: 660px;
    //   height: 100%;
    // }
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 20px 20px 0;
      img {
        width: 65px;
      }
      .close {
        cursor: pointer;
        width: 16px;
      }
    }

    &-body {
      margin-top: 40px;
      .title {
        margin: 0 24px 40px;
        text-align: left;
        color: $neutral-80;
        font-family: "GmarketSansBold";
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        span {
          // color: $primary-60;
          font-size: 24px;
          font-weight: 900;
          background: linear-gradient(180deg, #f49301 0%, #ffb800 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .sub-title {
        margin-top: 20px;
        color: $neutral-80;
        font-size: 28px;
        font-weight: 700;
        line-height: 28px;
      }
      .contents {
        margin: 0 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid $primary-20;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 12px;
        p {
          color: $neutral-80;
          font-family: "GmarketSansMedium";
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          span {
            font-family: "GmarketSansMedium";
            color: $primary-60;
          }
        }
      }
      .step {
        margin: 12px 24px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        color: $neutral-80;
        font-size: 15px;
        font-weight: 400;
        line-height: 140%;
      }
      .info {
        margin: 20px;
        .imgs {
          display: flex;
          gap: 12px;
          border-radius: 4px;
        }
        &-content {
          display: flex;
          flex-direction: column;
          gap: 8px;
          &-list {
            cursor: pointer;
            padding: 8px;
            display: flex;
            gap: 12px;
            border-radius: 4px;
            border: 1px solid $neutral-5;
            .img {
              width: 72px;
              height: 72px;
              overflow: hidden;
              border-radius: 4px;
              img {
                width: 100%;
              }
            }
            .item {
              &-name {
                font-size: 14px;
                font-weight: 500;
              }
              &-sale {
                display: flex;
                gap: 8px;
                font-size: 13px;
                font-weight: 400;
                color: $primary-60;
                span {
                  color: $neutral-40;
                  text-decoration: line-through;
                }
              }
              &-price {
                display: flex;
                gap: 4px;
                align-items: flex-end;
                color: $primary-60;
                font-size: 15px;
                font-weight: 500;
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }

        // p {
        //   font-size: 15px;
        //   font-weight: 400;
        //   line-height: 140%;
        //   &:first-child {
        //     margin: 20px 0 12px;
        //   }
        // }
      }
    }
    &-bottom {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 194px;
      bottom: 0;
      padding-bottom: 20px;
      border-radius: 20px;
      border: 1px solid $neutral-5;
      background: $neutral-0;
      box-shadow: -8px 0px 16px 0px rgba(0, 0, 0, 0.08);
      background-image: url("@/assets/logo/osle-logo-background.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 385px 154px;
      @media (max-width: 430px) {
        background-size: 315px 126px;
      }
      // bottom: 40px;
      &-info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        p {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
          color: $neutral-80;
          span {
            font-weight: 500;
            &.yl {
              color: $primary-60;
            }
          }
        }
        .mr-12 {
          height: 26px;
          margin-bottom: 2px;
        }
      }
    }
    &-btns {
      border-radius: 0 0 16px 16px;
      padding: 20px;
      background-color: $neutral-3;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-btn {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: $neutral-0;
        &-left {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          height: 40px;
          background: $neutral-60;
          border-radius: 4px 0px 0px 4px;
        }
        &-right {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          height: 40px;
          background: $primary-50;
          border-radius: 0px 4px 4px 0px;
        }
      }
      &-chk {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        color: $neutral-80;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &-matching-fin {
    position: fixed;
    z-index: 1001;
    top: 50%;
    left: 50%;
    max-width: 360px;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: $neutral-0;
    border-radius: 8px;
    text-align: center;
    color: $neutral-80;
    .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
    &-body {
      margin: 80px 0 60px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    &-bottom {
      cursor: pointer;
      border-radius: 0 0 8px 8px;
      display: flex;
      color: $neutral-0;
      align-items: center;
      .btn {
        &-left {
          flex: 1;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          background: $primary-50;
          border-radius: 0 0 8px 0;
        }
        &-right {
          flex: 1;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          background: $neutral-60;
          border-radius: 0 0 0 8px;
        }
      }
    }
  }
}

.toast-msg {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 60px;
  background-color: $primary-50;
  max-width: 540px;
  width: 100%;
  height: 54px;
  /* 토스트 팝업의 opacity가 변할 때 부드럽게 애니메이션을 적용합니다. */
  transition: opacity 0.5s ease;
  opacity: 0.8; /* 초기에 투명도를 설정합니다. */
  visibility: visible;
  @media (max-width: 500px) {
    width: calc(100% - 40px);
  }
  p {
    color: $neutral-0;
    font-size: 16px;
    font-weight: 400;
  }
}

/* 토스트 팝업이 숨겨질 때 투명도를 조정하여 부드러운 애니메이션을 적용합니다. */
.toast-msg:not(.showing-toast) {
  opacity: 0;
  visibility: hidden;
}
</style>
